import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 57;
const APP_BAR_DESKTOP = 65;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function MainLayout(): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      <Header
        onOpenNav={() => {
          setOpen(true);
        }}
      />

      <Nav
        openNav={open}
        onCloseNav={() => {
          setOpen(false);
        }}
      />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
