import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { FC } from 'react';

const RouteChangeTracker: FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_METRIKA_ACCOUNT) {
      ym(+process.env.REACT_APP_METRIKA_ACCOUNT, 'hit', location.pathname);
    }
  }, [location.pathname]);

  return null;
};

export default RouteChangeTracker;
