/* eslint-disable no-template-curly-in-string */
import type { LocaleObject } from 'yup';

export const ruLocale: LocaleObject = {
  mixed: {
    default: 'Некорректное значение',
    required: 'Это поле обязательно для заполнения',
    oneOf: 'Значение должно быть одним из следующих: ${values}',
    notOneOf: 'Значение не должно быть одним из следующих: ${values}',
  },
  string: {
    length: 'Длина должна быть равна ${length}',
    min: 'Длина должна быть не менее ${min} символов',
    max: 'Длина должна быть не более ${max} символов',
    email: 'Введите корректный email',
    url: 'Введите корректный URL-адрес',
    trim: 'Значение не должно содержать пробелов в начале или конце строки',
    lowercase: 'Значение должно быть в нижнем регистре',
    uppercase: 'Значение должно быть в верхнем регистре',
    matches:
      'Значение должно соответствовать следующему регулярному выражению: "${regex}"',
  },
  number: {
    min: 'Значение должно быть не менее ${min}',
    max: 'Значение должно быть не более ${max}',
    lessThan: 'Значение должно быть меньше ${less}',
    moreThan: 'Значение должно быть больше ${more}',
    positive: 'Значение должно быть положительным',
    negative: 'Значение должно быть отрицательным',
    integer: 'Значение должно быть целым числом',
  },
  date: {
    min: 'Дата должна быть позднее ${min}',
    max: 'Дата должна быть раньше ${max}',
  },
  boolean: {
    isValue: '${path} должен быть ${value}',
  },
  object: {
    noUnknown: 'Значение содержит неизвестные ключи: ${unknown}',
  },
  array: {
    length: 'Количество элементов должно быть равно ${length}',
    min: 'Минимальное количество элементов: ${min}',
    max: 'Максимальное количество элементов: ${max}',
  },
};
