import { useRef, useEffect, useState } from 'react';

function useDocumentTitle(initialTitle: string): [string, (newTitle: string) => void] {
  const defaultTitle = useRef(document.title);
  const [currentTitle, setCurrentTitle] = useState(initialTitle);

  const setTitle = (newTitle: string): void => {
    document.title = `${newTitle} | МастерСмет`;
    setCurrentTitle(newTitle);
  };

  useEffect(() => {
    document.title = `${currentTitle} | МастерСмет`;
  }, [currentTitle]);

  useEffect(
    () => () => {
      document.title = defaultTitle.current;
    },
    []
  );

  return [currentTitle, setTitle];
}

export default useDocumentTitle;
