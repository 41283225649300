import { Button, Link, ThemeProvider, useTheme, createTheme } from '@mui/material';
import { useMemo } from 'react';
import OriginCookieConsent from 'react-cookie-consent';
import type { FC } from 'react';

const CookieConsent: FC = () => {
  const theme = useTheme();

  const consentTheme = useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          mode: 'dark',
        },
      }),
    [theme]
  );

  return (
    <ThemeProvider theme={consentTheme}>
      <OriginCookieConsent
        buttonText='Подтвердить согласие'
        style={{
          backgroundColor: consentTheme.palette.background.default,
          color: consentTheme.palette.text.primary,
          borderTop: `1px solid ${consentTheme.palette.divider}`,
          zIndex: 999999,
        }}
        acceptOnScroll
        ButtonComponent={Button}
        buttonStyle={{
          backgroundColor: consentTheme.palette.primary.main,
        }}>
        Наш сайт использует технологию{' '}
        <Link href='https://ru.wikipedia.org/wiki/Cookie'>Cookie</Link>. Оставаясь на
        ресурсе Вы принимаете Соглашение об использовании файлов cookie.
      </OriginCookieConsent>
    </ThemeProvider>
  );
};

export default CookieConsent;
