import { AxiosError } from 'axios';
import { getErrorMessage } from './commonUtils';

export function getResponseErrorMessage<T extends Error>(err: T): string {
  const axiosError =
    err instanceof AxiosError
      ? typeof err.response?.data === 'string'
        ? err.response?.data
        : JSON.stringify(err.response?.data)
      : '';

  return axiosError || getErrorMessage(err);
}

export function getApiResponseErrorMessage<T extends Error>(err: T): string {
  const apiError = err instanceof AxiosError ? String(err.response?.data ?? '') : '';

  return apiError || getResponseErrorMessage(err);
}
