import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Button, Stack } from '@mui/material';
import LoadingButton from 'core/components/LoadingButton';
import PageHeaderBackLink from 'layout/pages/PageHeaderBackLink';
import PageHeaderText from 'layout/pages/PageHeaderText';
import PageHeaderWrapper from 'layout/pages/PageHeaderWrapper';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import type { FC } from 'react';

const RequestCreatePageHeader: FC<RequestCreatePageHeaderProps> = ({
  saving,
  onSave,
}) => {
  const navigate = useNavigate();

  const handleCreateClick: VoidFunction = () => {
    !isNil(onSave) && onSave();
  };
  const handleCancelClick: VoidFunction = () => {
    navigate('/requests');
  };

  return (
    <PageHeaderWrapper alignItems={{ xs: 'center' }}>
      <Stack>
        <PageHeaderBackLink to={'/requests'} name={'Заявки'} />
        <PageHeaderText align={['center', 'left']} text={'Новая заявка'} />
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <LoadingButton
          loading={saving}
          loadingPosition='start'
          disabled={saving}
          startIcon={<SaveOutlinedIcon />}
          onClick={handleCreateClick}
          variant='contained'>
          <span>Создать</span>
        </LoadingButton>
        <Button onClick={handleCancelClick} variant='outlined'>
          Отмена
        </Button>
      </Stack>
    </PageHeaderWrapper>
  );
};

export interface RequestCreatePageHeaderProps {
  saving?: boolean;
  onSave?: VoidFunction;
}

export default RequestCreatePageHeader;
