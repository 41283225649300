/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
import { isNil } from 'lodash';
import type { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (!isNil(onPerfEntry) && onPerfEntry instanceof Function) {
    import('web-vitals').then(
      ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      },
      () => {
        throw new Error('web-vitals error');
      }
    );
  }
};

export default reportWebVitals;
