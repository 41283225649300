import { isNil } from 'lodash';
import { makeAutoObservable } from 'mobx';
import type RootStore from './rootStore';
import type { AlertColor } from '@mui/material';

export default class NotifyStore {
  notify: { message: string; severity: AlertColor; open: boolean } | null = null;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  showNotify(message: string, severity: AlertColor): void {
    this.notify = { message, severity, open: true };
  }

  closeNotify(): void {
    !isNil(this.notify) && (this.notify.open = false);
  }
}
