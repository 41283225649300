import { isEmpty } from 'lodash';
import { string } from 'yup';
import { isBIC, isCorrespondentAccount, isPaymentAccount } from './bank-account';
import { isINN } from './inn';
import { isINNLegalEntity } from './inn/entity';
import { isINNIndividual } from './inn/individual';
import { isKPP } from './kpp';
import { isOGRN } from './ogrn';
import type { Reference } from 'yup';

export const kppValidator = string().test({
  name: 'kpp',
  message: 'Некорректный КПП',
  exclusive: false,
  test: (val) => isEmpty(val) || isKPP(val ?? ''),
});

export const ogrnValidator = string().test({
  name: 'ogrn',
  message: 'Некорректный ОГРН',
  exclusive: false,
  test: (val) => isEmpty(val) || isOGRN(val ?? ''),
});

export const innValidator = string().test({
  name: 'inn',
  message: 'Некорректный ИНН',
  exclusive: false,
  test: (val) => isEmpty(val) || isINN(val ?? ''),
});

export const entityInnValidator = string().test({
  name: 'entityInn',
  message: 'Некорректный ИНН',
  exclusive: false,
  test: (val) => isEmpty(val) || isINNLegalEntity(val ?? ''),
});

export const individualInnValidator = string().test({
  name: 'individualInn',
  message: 'Некорректный ИНН',
  exclusive: false,
  test: (val) => isEmpty(val) || isINNIndividual(val ?? ''),
});

export const bankAccount = {
  bicValidator: string().test({
    name: 'bankBic',
    message: 'Некорректный БИК',
    exclusive: false,
    test: (val) => isEmpty(val) || isBIC(val ?? ''),
  }),

  paymentAccountValidator: (bic: Reference<string>) =>
    string().test({
      name: 'bankPaymentAccount',
      message: 'Некорректный Р/С. Проверьте БИК',
      exclusive: false,
      test: (val, context) =>
        isEmpty(val) || isPaymentAccount(val ?? '', context.resolve(bic)),
    }),

  correspondentAccountValidator: (bic: Reference<string>) =>
    string().test({
      name: 'bankCorrespondentAccount',
      message: 'Некорректный К/С. Проверьте БИК',
      exclusive: false,
      test: (val, context) =>
        isEmpty(val) || isCorrespondentAccount(val ?? '', context.resolve(bic)),
    }),
};
