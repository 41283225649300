import { isBIC } from './bic';
/**
 * match bank branch (correspondent account & bic)
 *
 * @param value string
 * @param bic string
 * @returns boolean
 */
export const matchFilial = (value: string, bic: string): boolean =>
  bic.slice(-3) === value.slice(-3);

/**
 * check is correspondent account
 *
 * @param value string
 * @param bic string
 * @returns boolean
 */
export const isCorrespondentAccount = (value: string, bic: string): boolean => {
  const valueToString = value ? value.toString() : '';
  if (!matchFilial(valueToString, bic)) return false;
  if (isBIC(bic)) {
    if (!/[^0-9]/.test(valueToString) && valueToString.length === 20) {
      const bicKs = '0' + bic.slice(4, 6) + valueToString;
      let checkSum = 0;
      const coefficients = [
        7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1,
      ] as const;

      coefficients.forEach((_, i) => {
        if (i) checkSum += (coefficients[i] ?? 0) * (Number(bicKs[i]) % 10);
      });
      return checkSum % 10 === 0;
    }
  }
  return false;
};
