import { isEmpty } from 'lodash';
import { string } from 'yup';
import { parseGeolocationString } from '../orderUtils';

/**
 * Функция, создающая валидатор, проверяющий строку на соответствие следующим критериям:
 * строка должна начинаться с символа "7" и содержать 10 цифр после него.
 *
 * @function
 * @returns {object} Валидатор Yup для проверки номера телефона.
 */
export const phoneValidator = string().matches(
  /^7(\d{10})$/,
  'некорректный номер телефона'
);

export const geolocationValidator = string().test(
  'geolocationCoordinates',
  'Неверный формат',
  (v) => {
    if (isEmpty(v)) return true;

    try {
      parseGeolocationString(v ?? '');

      return true;
    } catch {
      return false;
    }
  }
);
