import { Stack } from '@mui/material';
import type { StackProps } from '@mui/material';
import type { FC } from 'react';

const PageHeaderWrapper: FC<StackProps> = ({ children, ...other }) => (
  <Stack
    direction={['column', 'row']}
    alignItems={['stretch', 'center']}
    justifyContent={['flex-start', 'space-between']}
    width={'100%'}
    px={[2, 3]}
    rowGap={[2, 0]}
    columnGap={0}
    {...other}>
    {children}
  </Stack>
);

export default PageHeaderWrapper;
