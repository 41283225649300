import { last } from 'lodash';
import { matchRoutes, useLocation } from 'react-router-dom';
import type { Location, RouteMatch, RouteObject } from 'react-router-dom';

const useCurrentRoute = (routes: RouteObject[]): CurrentRoute | undefined => {
  const location = useLocation();
  const matches = matchRoutes(routes, location);

  const lastMatch = last(matches);

  if (lastMatch) {
    return {
      ...lastMatch,
      ...location,
    };
  }

  return undefined;
};

export interface CurrentRoute extends Location, RouteMatch<string, RouteObject> {}

export default useCurrentRoute;
