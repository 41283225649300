import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import type { FC } from 'react';

const PageHeaderBackLink: FC<PageHeaderBackLinkProps> = ({ to, name }) => (
  <Link to={to} style={{ textDecoration: 'none' }}>
    <Stack direction='row' spacing={0.5} sx={{ alignItems: 'center' }}>
      <ArrowBackIcon sx={{ color: 'text.secondary' }} fontSize='small' />
      <Typography color={'textSecondary'} variant='body1'>
        {name}
      </Typography>
    </Stack>
  </Link>
);

export interface PageHeaderBackLinkProps {
  to: string;
  name: string;
}

export default PageHeaderBackLink;
