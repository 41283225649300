import { isBIC } from './bic';

/**
 * check is Payment Account
 *
 * @param value string
 * @param bic string
 * @returns boolean
 */
export const isPaymentAccount = (value: string, bic: string): boolean => {
  const valueToString = value ? value.toString() : '';

  if (!isBIC(bic)) return false;

  if (valueToString.length !== 20 || /[^0-9]/.test(valueToString)) {
    return false;
  }

  const bicRs = bic.toString().slice(-3) + valueToString;

  let checksum = 0;
  const coefficients = [
    7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1,
  ];

  coefficients.forEach((v, i) => {
    checksum += v * (Number(bicRs[i]) % 10);
  });

  return checksum % 10 === 0;
};
