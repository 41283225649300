import clientFormSchema from 'components/ClientForm/clientFormSchema';
import { geolocationValidator } from 'core/utils/validators/geolocation';
import { object, string } from 'yup';

const orderFormSchema = object().shape({
  remark: string().max(1000),
  warningComment: string().max(1000),
  address: string().ensure(),
  geolocation: geolocationValidator,
  client: clientFormSchema,
});

export default orderFormSchema;
