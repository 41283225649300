import { TextFieldElement } from 'react-hook-form-mui';
import PhoneField from './PhoneField';
import type { FC } from 'react';
import type { TextFieldElementProps } from 'react-hook-form-mui';

const PhoneFieldElement: FC<TextFieldElementProps> = (props) => (
  <TextFieldElement {...props} component={PhoneField} />
);

export default PhoneFieldElement;
