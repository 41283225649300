import { isNil } from 'lodash';
import type AccountData from 'models/accountData';

const USER_IDENTITY = 'user';
const ACCESS_TOKEN = 'acc';
const REFRESH_TOKEN = 'rfr';

export function getRefreshToken(): string | null {
  const accessToken = localStorage.getItem(REFRESH_TOKEN);

  return isNil(accessToken) ? null : accessToken;
}

export function setRefreshToken(refreshToken: string | null): void {
  if (!isNil(refreshToken)) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  } else {
    localStorage.removeItem(REFRESH_TOKEN);
  }
}

export function getAccessToken(): string | null {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  return isNil(accessToken) ? null : accessToken;
}

export function setAccessToken(accessToken: string | null): void {
  if (!isNil(accessToken)) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  } else {
    localStorage.removeItem(ACCESS_TOKEN);
  }
}

export function getUserData(): AccountData | null {
  const lsUser = localStorage.getItem(USER_IDENTITY);

  return isNil(lsUser) ? null : (JSON.parse(lsUser) as AccountData);
}

export function setUserData(user: AccountData | null): void {
  if (!isNil(user)) {
    localStorage.setItem(USER_IDENTITY, JSON.stringify(user));
  } else {
    localStorage.removeItem(USER_IDENTITY);
  }
}
