import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import * as Sentry from '@sentry/react';
import routes from 'config/routesConfig';
import theme from 'config/themeConfig';
import CookieConsent from 'core/components/CookieConsent';
import Notify from 'core/components/Notify';
import UnsupportedBrowserWarning from 'core/components/UnsupportedBrowserWarning';
import dayjs from 'dayjs';
import { ConfirmProvider } from 'material-ui-confirm';
import { reaction } from 'mobx';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import StoreProvider from 'stores/providers/StoreProvider';
import { rootStore } from './stores';

import './sentry';

import 'dayjs/locale/ru';

dayjs.locale('ru');

const App = (): JSX.Element => {
  reaction(
    () => rootStore.userStore.userData,
    () => {
      rootStore.shopStore.updateShopInfo();
    },
    { fireImmediately: true }
  );

  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <StoreProvider>
              <ConfirmProvider
                defaultOptions={{
                  title: 'Подтверждение действия',
                  confirmationText: 'Подтвердить',
                  cancellationText: 'Отмена',
                  buttonOrder: ['confirm', 'cancel'],
                }}>
                <Notify />
                <CookieConsent />
                <RouterProvider router={routes} />
              </ConfirmProvider>
            </StoreProvider>
          </LocalizationProvider>
          <UnsupportedBrowserWarning />
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export default Sentry.withProfiler(App);
