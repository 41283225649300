import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { StackProps } from '@mui/material';

const SimplePageHeader = styled((props: StackProps) => (
  <Stack direction={{ xs: 'column', sm: 'row' }} pt={1} pb={4} spacing={0} {...props} />
))({
  gap: 2,
  width: '100%',
});

// const PageSimpleHeader: FC<PropsWithChildren> = ({ children }) => (
//   <Stack
//     direction={{ xs: 'column', sm: 'row' }}
//     width='100%'
//     py={3}
//     spacing={0}
//     sx={{ gap: 2 }}>
//     {children}
//   </Stack>
// );

export default SimplePageHeader;
