import { Box, List, ListItemText } from '@mui/material';
import { Can } from 'core/components/permissions/Can';
import { isNil } from 'lodash';
import { NavLink as RouterLink } from 'react-router-dom';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import type { BoxProps } from '@mui/material';
import type { NavConfigItem } from 'config/navConfig';
import type { FC, ReactElement } from 'react';

function NavItem({ item }: { key: string; item: NavConfigItem }): JSX.Element {
  const { title, path, icon, info } = item;

  return (
    <li>
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}>
        <StyledNavItemIcon>{!isNil(icon) && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {!isNil(info) && info}
      </StyledNavItem>
    </li>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavSection: FC<BoxProps<any> & { data: NavConfigItem[] }> = ({
  data = [],
  ...other
}) => {
  const getNavItem = (item: NavConfigItem): ReactElement => (
    <NavItem key={item.title} item={item} />
  );

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) =>
          isNil(item.permission) ? (
            getNavItem(item)
          ) : (
            <Can key={item.title} I='read' a={item.permission}>
              {getNavItem(item)}
            </Can>
          )
        )}
      </List>
    </Box>
  );
};
export default NavSection;
