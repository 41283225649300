import { Typography } from '@mui/material';

type Aligns = 'right' | 'left' | 'center' | 'inherit' | 'justify';

const PageHeaderText = ({
  text,
  align,
  ...typographyProps
}: PageHeaderProps & {
  align?: Aligns | Aligns[] | undefined;
}): JSX.Element => (
  <Typography
    component='h1'
    variant='h4'
    sx={{ fontWeight: 'bold', textAlign: align }}
    {...typographyProps}>
    {text}
  </Typography>
);

export interface PageHeaderProps {
  text: string;
}

export default PageHeaderText;
