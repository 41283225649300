import { createCanBoundTo } from '@casl/react';
import { observer } from 'mobx-react-lite';
import { useContext, type FC } from 'react';
import StoreContext from 'stores/context/StoreContext';
import type { BoundCanProps } from '@casl/react';
import type { AppAbility } from 'core/permissions/defineAbilityFor';

// const Can = createCanBoundTo(rootStore.userStore.ability);

// Создайте оберточный компонент-observer для Can
const ObserverCan: FC<BoundCanProps<AppAbility>> = observer((props) => {
  const { userStore } = useContext(StoreContext);

  const Can = createCanBoundTo(userStore.ability);

  return <Can {...props} />;
});

export { ObserverCan as Can };
