import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import { Alert, AlertTitle, Fade } from '@mui/material';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import { red } from '@mui/material/colors';
import supportedBrowsers from 'core/utils/supportedBrowsers';
import { useState } from 'react';
import type { FC } from 'react';

const UnsupportedBrowserWarning: FC = () => {
  const [bannerOpen, setBannerOpen] = useState(
    !supportedBrowsers.test(navigator.userAgent)
  );

  const closeBanner = (): void => {
    setBannerOpen(false);
  };

  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={false} in={bannerOpen}>
        <Alert
          onClose={closeBanner}
          icon={<BrowserNotSupportedIcon sx={{ fontSize: 45 }} />}
          severity='error'
          role='dialog'
          aria-modal='false'
          aria-label='Браузер не поддерживается'
          square
          variant='standard'
          tabIndex={-1}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            p: 2,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: red[900],
            borderStyle: 'solid',
            zIndex: 1500,
          }}>
          <AlertTitle>Ваш браузер не поддерживается</AlertTitle>
          Приложение может работать некорректно. Обновите браузер.
        </Alert>
      </Fade>
    </TrapFocus>
  );
};

export default UnsupportedBrowserWarning;
