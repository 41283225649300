import { Box, Divider, Typography, MenuItem, IconButton, Popover } from '@mui/material';
import ColoredAvatar from 'core/components/ColoredAvatar';
import { getFormattedRuPhone } from 'core/utils/commonUtils';
import { isNil } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StoreContext from 'stores/context/StoreContext';

export const AccountPopover = observer((): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { authStore, userStore } = useContext(StoreContext);
  const navigate = useNavigate();

  const { userData } = userStore;

  const signOut = (): void => {
    void authStore
      .signOut()
      .then(() => ({}))
      .catch(console.log.bind(console));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const toHome: VoidFunction = () => {
    navigate('/');
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} sx={{ p: 0 }}>
        <ColoredAvatar alt={userData?.surname}>{userData?.surname?.[0]}</ColoredAvatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}>
        {!isNil(userData) && (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant='subtitle2' noWrap>
              {userData.surname} {userData.name}
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
              {getFormattedRuPhone(userData.phone)}
            </Typography>
          </Box>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={toHome}>
          Домашняя страница
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={signOut} sx={{ m: 1 }}>
          Выйти
        </MenuItem>
      </Popover>
    </>
  );
});

export default AccountPopover;
