import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from 'core/utils/localStorage';
import jwtDecode from 'jwt-decode';
import { isEmpty, isNil } from 'lodash';
import { autorun, computed, makeAutoObservable, runInAction, toJS } from 'mobx';
import authService from 'services/authService';
import type RootStore from './rootStore';
import type { AccessTokenData } from 'models/accessTokenData';
import type AccountData from 'models/accountData';
import type { SignInRequest } from 'services/dtoModels/auth/signInQuery';

export default class AuthStore {
  accessToken: string | null = null;
  refreshToken: string | null = null;

  readonly isAuthenticated = computed(
    () => !isEmpty(this.accessToken) && !isEmpty(this.refreshToken)
  );

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);

    this.accessToken = getAccessToken();
    this.refreshToken = getRefreshToken();

    autorun(() => {
      const value = toJS(this);

      setAccessToken(value.accessToken);
      setRefreshToken(value.refreshToken);
    });
  }

  async signIn(data: SignInRequest): Promise<void> {
    const { accessToken, refreshToken } = await authService.signIn(data);

    runInAction(() => {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    });

    this.setUserData(accessToken);
  }

  async signOut(): Promise<void> {
    await authService.signOut();

    runInAction(() => {
      this.accessToken = null;
      this.refreshToken = null;
    });

    this.setUserData(null);
  }

  async updateToken(): Promise<void> {
    const { accessToken, refreshToken } = await authService.updateToken({
      refreshToken: this.refreshToken ?? '',
    });
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.setUserData(this.accessToken);
  }

  private setUserData(accessToken: string | null): void {
    let user: AccountData | null = null;

    if (!isNil(accessToken) && accessToken.length) {
      const tokenData = jwtDecode<AccessTokenData>(accessToken);

      user = {
        id: +tokenData.Id,
        shopId: +tokenData.ShopId,
        name: tokenData.Name,
        surname: tokenData.Surname,
        patronymic: tokenData.Patronymic,
        phone: tokenData.Phone,
        role: +tokenData.Role,
        actionAccess: tokenData.ActionAccesses.split(',').map((v) => +v),
        permissions: [], // tokenData.permissions,
      };
    }

    this.rootStore.userStore.setUserData(user);
  }
}
