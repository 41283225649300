import { isNil } from 'lodash';
import { action, makeAutoObservable } from 'mobx';
import * as shopService from 'services/shopService';
import type RootStore from './rootStore';
import type { Shop } from 'models/shop';

export default class ShopStore {
  shop?: Shop;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  updateShopInfo = action((): void => {
    if (isNil(this.rootStore.userStore.userData)) {
      this.shop = undefined;
      return;
    }

    shopService
      .getShopAsync(this.rootStore.userStore.userData.shopId)
      .then((s) => {
        this.shop = s;

        return s;
      })
      .catch(console.log);
  });
}
