import { Box, CircularProgress, Typography } from '@mui/material';

export default function Loading(): JSX.Element {
  return (
    <Box
      display={'flex'}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      p={4}
      height={'100%'}
      width={'100%'}>
      <CircularProgress color='secondary' />
      <Typography variant='body1' mb={2} sx={{}} color='textSecondary'>
        Загрузка...
      </Typography>
    </Box>
  );
}
