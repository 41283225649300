import axios from 'axios';
import { isEmpty } from 'lodash';
import qs from 'qs';
import type { CreateAxiosDefaults, AxiosInstance, AxiosError } from 'axios';

export const axiosDefaultConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_API ?? '',
  headers: {
    common: { 'Content-Type': 'application/json' },
  },
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  },
};

const setInterceptors =
  (privateInstance: AxiosInstance, publicInstance: AxiosInstance) =>
  (
    getAccessToken: () => string | null,
    updateAccessToken: () => Promise<void>,
    signOut: VoidFunction,
    onError: (err: AxiosError) => void
  ): void => {
    publicInstance.interceptors.request.use(
      (config) => config,
      (error: AxiosError) => {
        onError(error);
        throw error;
      }
    );

    publicInstance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        onError(error);
        throw error;
      }
    );

    privateInstance.interceptors.request.use(
      (config) => {
        const token = getAccessToken();
        if (!isEmpty(token)) {
          config.headers.Authorization = `Bearer ${token ?? ''}`;
        }

        return config;
      },
      async (error: AxiosError) => {
        onError(error);
        throw error;
      }
    );

    privateInstance.interceptors.response.use(
      (response) => response,
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      async (reqErr: AxiosError & { config: { __isRetryRequest?: boolean } }) => {
        try {
          const isRetryRequest = reqErr.config?.__isRetryRequest ?? false;

          if (reqErr.response?.status === 401 && !isRetryRequest) {
            await updateAccessToken();

            reqErr.config.__isRetryRequest = true;
            reqErr.config.headers.Authorization = `Bearer ${getAccessToken() ?? ''}`;

            return await axios(reqErr.config);
          }
        } catch (err) {
          console.log(err);
          signOut();
        }

        onError(reqErr);
        throw reqErr;
      }
    );
  };

export const http = axios.create(axiosDefaultConfig);
export const publicHttp = axios.create(axiosDefaultConfig);
export const setAuthInterceptors = setInterceptors(http, publicHttp);
