import { ClientTypeEnum } from 'models/enums/clientTypeEnum';

/**
 * Функция, которая возвращает название типа клиента в зависимости от переданного типа.
 *
 * @param {ClientTypeEnum} type Тип клиента.
 * @returns {string} Название типа клиента ("Физическое лицо" или "Юридическое лицо"), либо строку смайлика, если передан некорректный тип.
 */
export function getClientTypeName(type: ClientTypeEnum): string {
  switch (type) {
    case ClientTypeEnum.Individual:
      return 'Физическое лицо';
    case ClientTypeEnum.Entity:
      return 'Юридическое лицо';
    case ClientTypeEnum.IndividualEntrepreneur:
      return 'Индивидуальный предприниматель';
    default:
      return `ಠ╭╮ಠ`;
  }
}
