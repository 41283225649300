import { setAuthInterceptors } from 'core/utils/httpClient';
import { getApiResponseErrorMessage } from 'core/utils/httpUtils';
import AuthStore from './authStore';
import NotifyStore from './notifyStore';
import ShopStore from './shopStore';
import UserStore from './userStore';

export default class RootStore {
  userStore: UserStore;
  authStore: AuthStore;
  notifyStore: NotifyStore;
  shopStore: ShopStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.authStore = new AuthStore(this);
    this.notifyStore = new NotifyStore(this);
    this.shopStore = new ShopStore(this);

    setAuthInterceptors(
      () => this.authStore.accessToken,
      // TODO: remove bind if updateToken is not class functions or arrow function
      this.authStore.updateToken.bind(this.authStore),
      () => {
        void this.authStore.signOut();
      },
      (err) => {
        this.notifyStore.showNotify(getApiResponseErrorMessage(err), 'error');
      }
    );
  }
}
