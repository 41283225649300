import { Avatar } from '@mui/material';
import { string2RGB } from 'core/utils/cssStyles';
import { isEmpty } from 'lodash';
import React from 'react';
import type { AvatarProps } from '@mui/material';

const ColoredAvatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  function ColoredAvatar(props, ref) {
    const { sx, ...other } = props;

    const color = isEmpty(props.alt) ? null : string2RGB(props.alt ?? '');

    return <Avatar ref={ref} sx={{ bgcolor: color, ...sx }} {...other} />;
  }
);

export default ColoredAvatar;
