import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Paper } from '@mui/material';
import LoadingButton from 'core/components/LoadingButton';
import PhoneFieldElement from 'core/components/PhoneField/PhoneFieldElement';
import { passwordValidator, phoneValidator } from 'core/utils/validators/common';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, PasswordElement } from 'react-hook-form-mui';
import { useLocation, useNavigate } from 'react-router-dom';
import StoreContext from 'stores/context/StoreContext';
import { object } from 'yup';
import { ReactComponent as Logo } from '../assets/logo.svg';
import type { SubmitHandler } from 'react-hook-form';
import type { SignInRequest } from 'services/dtoModels/auth/signInQuery';

type SignInFormData = SignInRequest;

const SignIn = observer((): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useContext(StoreContext);

  const schema = object<SignInFormData>({
    phone: phoneValidator.required(),
    password: passwordValidator.required(),
  }).required();

  const formContext = useForm<SignInFormData>({
    mode: 'onTouched',
    defaultValues: { phone: '7' },
    resolver: yupResolver(schema),
  });

  const { isSubmitting } = formContext.formState;

  const from = (location.state?.from?.pathname as string) || '/';

  const signIn: SubmitHandler<SignInFormData> = async (data) => {
    try {
      await authStore.signIn(data);
      navigate(from, { replace: true });

      return true;
    } catch (err) {
      console.log(err);

      return false;
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      sx={{ height: '100vh' }}>
      <Paper
        variant='outlined'
        square
        sx={{
          padding: 6,
          borderRadius: [0, 4],
          height: [1, 'auto'],
          width: 1,
          maxWidth: 'sm',
        }}>
        <FormContainer formContext={formContext} onSuccess={signIn}>
          <Box display={'flex'} flexDirection='column' alignItems='center' mb={3}>
            <Logo style={{ width: '50%' }} />
          </Box>
          <Box mb={3}>
            <PhoneFieldElement autoFocus name='phone' fullWidth margin='normal' />
            <PasswordElement label='Пароль' name='password' margin='normal' fullWidth />
          </Box>
          <Box display={'flex'} flexDirection='column' alignItems='center'>
            <LoadingButton
              loading={isSubmitting}
              variant='contained'
              type='submit'
              size='large'>
              <span>Войти</span>
            </LoadingButton>
          </Box>
        </FormContainer>
      </Paper>
    </Box>
  );
});

export default SignIn;
