import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { bgBlur } from 'core/utils/cssStyles';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useNavigation } from 'react-router-dom';
import StoreContext from 'stores/context/StoreContext';
import AccountPopover from './AccountPopover';
import type { FC } from 'react';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 57;

const HEADER_DESKTOP = 65;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.paper }),
  boxShadow: 'none',
  color: theme.palette.getContrastText(theme.palette.background.paper),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const Header: FC<{ onOpenNav: VoidFunction }> = observer(({ onOpenNav }): JSX.Element => {
  const navigation = useNavigation();
  const { shopStore } = useContext(StoreContext);

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}>
          <MenuIcon />
        </IconButton>

        <Typography variant='h6' component='div' fontWeight={'bold'} sx={{ flexGrow: 1 }}>
          {shopStore.shop?.name}
        </Typography>

        <Stack
          direction='row'
          alignItems='center'
          spacing={{
            xs: 0.5,
            sm: 1,
          }}>
          <AccountPopover />
        </Stack>
      </StyledToolbar>
      <Divider />
      {navigation.state !== 'idle' && (
        <Box>
          <LinearProgress />
        </Box>
      )}
    </StyledRoot>
  );
});

export default Header;
