import RouteAbilityGuard from 'core/auth/RouteAbilityGuard';
import RouteAuthGuard from 'core/auth/RouteAuthGuard';
import RouteNotAuthGuard from 'core/auth/RouteNotAuthGuard';
import CustomSuspense from 'core/components/CustomSuspense';
import RouteChangeTracker from 'core/components/RouterChangeTracker';
import RootErrorBoundary from 'errors/RootErrorBoundary';
import MainLayout from 'layout';
import { OrderStateEnum } from 'models/enums/orderStateEnum';
import RequestCreatePage from 'pages/OrderPage/RequestCreatePage';
import RouteOrderEditPageTabGuard from 'pages/OrderPage/route/RouteOrderEditPageTabGuard';
import SignIn from 'pages/SignIn';
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

const CalendarPage = React.lazy(
  async () => await import('pages/CalendarPage/CalendarPage')
);

const UsersPage = React.lazy(async () => await import('pages/UsersPage/UsersPage'));
const UserPage = React.lazy(async () => await import('pages/UserPage/UserPage'));
const UserEditPage = React.lazy(async () => await import('pages/UserPage/UserEditPage'));
const UserViewPage = React.lazy(async () => await import('pages/UserPage/UserViewPage'));

const ClientsPage = React.lazy(async () => await import('pages/ClientsPage/ClientsPage'));
const ClientPage = React.lazy(async () => await import('pages/ClientPage/ClientPage'));
const ClientEditPage = React.lazy(
  async () => await import('pages/ClientPage/ClientEditPage')
);
const ClientViewPage = React.lazy(
  async () => await import('pages/ClientPage/ClientViewPage')
);

const ProductsPage = React.lazy(
  async () => await import('pages/ProductsPage/ProductsPage')
);

const OrdersPage = React.lazy(async () => await import('pages/OrdersPage/OrdersPage'));

const OrderPage = React.lazy(async () => await import('pages/OrderPage/OrderPage'));
const OrderEditPage = React.lazy(
  async () => await import('pages/OrderPage/OrderEditPage')
);

const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <RouteChangeTracker />
        <MainLayout />
      </>
    ),
    errorElement: <RootErrorBoundary />,
    children: [
      {
        errorElement: <RootErrorBoundary />,
        children: [
          {
            index: true,
            element: <Navigate to='/calendar' state={{ replace: true }} />,
          },
          {
            path: '/calendar',
            element: (
              <RouteAuthGuard>
                <CustomSuspense>
                  <CalendarPage />
                </CustomSuspense>
              </RouteAuthGuard>
            ),
          },
          {
            path: '/requests',
            element: (
              <RouteAuthGuard>
                <CustomSuspense>
                  <OrdersPage ordersType={OrderStateEnum.Request} />
                </CustomSuspense>
              </RouteAuthGuard>
            ),
          },
          {
            path: '/requests/new',
            element: (
              <RouteAuthGuard>
                <CustomSuspense>
                  <RequestCreatePage />
                </CustomSuspense>
              </RouteAuthGuard>
            ),
          },
          {
            path: '/requests/:orderId',
            element: (
              <RouteAuthGuard>
                <CustomSuspense>
                  <OrderPage />
                </CustomSuspense>
              </RouteAuthGuard>
            ),
            children: [
              {
                path: '/requests/:orderId/:tab?',
                element: (
                  <RouteOrderEditPageTabGuard>
                    <CustomSuspense>
                      <OrderEditPage />
                    </CustomSuspense>
                  </RouteOrderEditPageTabGuard>
                ),
              },
            ],
          },
          ...(
            [
              ['/orders', OrderStateEnum.Order],
              ['/archive', OrderStateEnum.Archive],
            ] satisfies Array<[string, OrderStateEnum]>
          )
            .map(([p, s]) => [
              {
                path: p,
                element: (
                  <RouteAuthGuard>
                    <CustomSuspense>
                      <OrdersPage ordersType={s} />
                    </CustomSuspense>
                  </RouteAuthGuard>
                ),
              },
              {
                path: `${p}/:orderId`,
                element: (
                  <RouteAuthGuard>
                    <CustomSuspense>
                      <OrderPage />
                    </CustomSuspense>
                  </RouteAuthGuard>
                ),
                children: [
                  {
                    path: `${p}/:orderId/:tab?`,
                    element: (
                      <RouteOrderEditPageTabGuard>
                        <CustomSuspense>
                          <OrderEditPage />
                        </CustomSuspense>
                      </RouteOrderEditPageTabGuard>
                    ),
                  },
                ],
              },
            ])
            .flat(),
          {
            path: '/users',
            element: (
              <RouteAuthGuard>
                <RouteAbilityGuard ability={'Users'}>
                  <CustomSuspense>
                    <UsersPage />
                  </CustomSuspense>
                </RouteAbilityGuard>
              </RouteAuthGuard>
            ),
          },
          {
            path: '/users/:userId',
            element: (
              <RouteAuthGuard>
                <RouteAbilityGuard ability={'Users'}>
                  <CustomSuspense>
                    <UserPage />
                  </CustomSuspense>
                </RouteAbilityGuard>
              </RouteAuthGuard>
            ),
            children: [
              {
                path: '/users/:userId',
                element: (
                  <CustomSuspense>
                    <UserViewPage />
                  </CustomSuspense>
                ),
              },
              {
                path: '/users/:userId/edit',
                element: (
                  <CustomSuspense>
                    <UserEditPage />
                  </CustomSuspense>
                ),
              },
            ],
          },
          {
            path: '/clients',
            element: (
              <RouteAuthGuard>
                <RouteAbilityGuard ability={'Clients'}>
                  <CustomSuspense>
                    <ClientsPage />
                  </CustomSuspense>
                </RouteAbilityGuard>
              </RouteAuthGuard>
            ),
          },
          {
            path: '/clients/:clientId',
            element: (
              <RouteAuthGuard>
                <CustomSuspense>
                  <ClientPage />
                </CustomSuspense>
              </RouteAuthGuard>
            ),
            children: [
              {
                path: '/clients/:clientId',
                element: (
                  <CustomSuspense>
                    <ClientViewPage />
                  </CustomSuspense>
                ),
              },
              {
                path: '/clients/:clientId/edit',
                element: (
                  <CustomSuspense>
                    <ClientEditPage />
                  </CustomSuspense>
                ),
              },
            ],
          },
          {
            path: '/products',
            element: (
              <RouteAuthGuard>
                <RouteAbilityGuard ability={'Products'}>
                  <CustomSuspense>
                    <ProductsPage />
                  </CustomSuspense>
                </RouteAbilityGuard>
              </RouteAuthGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: (
      <RouteNotAuthGuard>
        <SignIn />
      </RouteNotAuthGuard>
    ),
  },
]);

export default routes;
