import { UserRoleEnum } from 'models/enums/userRoleEnum';

/**
 * Получает название роли пользователя на основе переданного значения
 *
 * @param {UserRoleEnum} role Значение, представляющее роль пользователя
 * @returns {string} Название роли пользователя
 */
export function getRoleName(role: UserRoleEnum): string {
  switch (role) {
    case UserRoleEnum.Supervisor:
      return 'Администратор';

    case UserRoleEnum.Manager:
      return 'Менеджер';

    case UserRoleEnum.Worker:
      return 'Рабочий';

    default:
      return `ಠ╭╮ಠ`;
  }
}
