import { http } from 'core/utils/httpClient';
import type { DadataBankResponse } from './dtoModels/dadata/dadataBankResponse';
import type { DadataPartyResponse } from './dtoModels/dadata/dadataPartyResponse';
import type { DadataBank } from 'models/dadata/dadataBank';
import type { DadataParty } from 'models/dadata/dadataParty';

export const mapDadataBank = (source: DadataBankResponse): DadataBank => ({
  ...source,
  bic: source.bic ?? '',
  inn: source.inn ?? '',
  kpp: source.kpp ?? '',
  name: source.name ?? '',
  correspondentAccount: source.correspondentAccount ?? '',
});

export const mapDadataParty = (source: DadataPartyResponse): DadataParty => ({
  ...source,
  inn: source.inn ?? '',
  kpp: source.kpp ?? '',
  address: source.address ?? '',
  fullName: source.fullName ?? '',
  managementFullName: source.managementFullName ?? '',
  ogrn: source.ogrn ?? '',
});

export async function getPartyAsync(inn: string): Promise<DadataParty> {
  const { data } = await http.get<DadataPartyResponse>('dadata/party', {
    params: { inn },
  });

  return mapDadataParty(data);
}

export async function getBankAsync(bic: string): Promise<DadataBank> {
  const { data } = await http.get<DadataBankResponse>('dadata/bank', {
    params: { bic },
  });

  return mapDadataBank(data);
}
