import { cleanPhone } from 'core/utils/commonUtils';
import { isNil } from 'lodash';
import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import type { ClipboardEventHandler } from 'react';

interface PhoneInputProps {
  onChange: (event: { target: { name: string; value: unknown } }) => void;
  name: string;
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(function PhoneInput(
  props,
  ref
) {
  const { onChange, ...other } = props;

  let value = '';

  const handlePaste: ClipboardEventHandler<HTMLDivElement> = (event): void => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('Text');

    const clearedPhone = cleanPhone(pastedText);

    if (!isNil(ref)) {
      !isNil(onChange) && onChange({ target: { name: props.name, value: clearedPhone } });
    }
  };

  return (
    <IMaskInput
      {...other}
      onPaste={handlePaste}
      mask={'{7} (000) 000-00-00'}
      inputRef={ref}
      onAccept={(v: string) => {
        value = v;
      }}
      onChange={() => {
        onChange({ target: { name: props.name, value } });
      }}
      type='tel'
      unmask
    />
  );
});

export default PhoneInput;
