import { Box, Link as MuiLink, Drawer, Typography, Toolbar } from '@mui/material';
import Divider from '@mui/material/Divider/Divider';
import {
  styled,
  alpha,
  ThemeProvider,
  createTheme,
  useTheme,
} from '@mui/material/styles';
import NavSection from 'components/NavSection';
import ColoredAvatar from 'core/components/ColoredAvatar';
import { getRoleName } from 'core/utils/userUtils';
import useResponsive from 'hooks/useResponsive';
import { isNil } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StoreContext from 'stores/context/StoreContext';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { navConfig } from '../../config/navConfig';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function Nav({
  openNav,
  onCloseNav,
}: {
  openNav: boolean;
  onCloseNav: VoidFunction;
}): JSX.Element {
  const { pathname } = useLocation();
  const { userStore } = useContext(StoreContext);
  const theme = useTheme();

  const account = userStore.userData;

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const navTheme = useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          mode: 'dark',
        },
      }),
    [theme]
  );

  const RenderContent = observer(() => (
    <>
      <Toolbar component={Link} to={'/'}>
        <Logo
          style={{
            fill: navTheme.palette.text.primary,
            width: isDesktop ? '60%' : '53%',
          }}
        />
      </Toolbar>

      <Divider sx={{ mb: 2 }} />

      {isDesktop && !isNil(account) && (
        <Box sx={{ mb: 3, mx: 2.5 }}>
          <MuiLink underline='none'>
            <StyledAccount>
              <ColoredAvatar alt={account?.surname}>
                {account?.surname?.[0]}
              </ColoredAvatar>

              <Box sx={{ ml: 2 }}>
                <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
                  {account.surname} {account.name}
                </Typography>

                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  {getRoleName(account.role)}
                </Typography>
              </Box>
            </StyledAccount>
          </MuiLink>
        </Box>
      )}

      <Box style={{ flexGrow: 1, overflow: 'auto' }}>
        <NavSection data={navConfig} />
      </Box>

      <Box sx={{ p: 1, color: 'text.primary' }}>
        <Typography variant='caption'>
          {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </>
  ));

  return (
    <ThemeProvider theme={navTheme}>
      <Box
        component='nav'
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV_WIDTH },
        }}>
        {isDesktop ? (
          <Drawer
            open
            variant='permanent'
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: 'background.default',
                borderRightStyle: 'dashed',
                overflow: 'hidden',
              },
            }}>
            <RenderContent />
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}>
            <RenderContent />
          </Drawer>
        )}
      </Box>
    </ThemeProvider>
  );
}
