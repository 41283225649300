import { ruLocale } from 'core/utils/yupRuI18n';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import { setLocale as setYupLocale } from 'yup';
import App from './App';
import './index.css';

setYupLocale(ruLocale);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
