import { Snackbar, useMediaQuery } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import StoreContext from 'stores/context/StoreContext';
import type { SnackbarCloseReason, SnackbarOrigin } from '@mui/material';
import type { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Notify = observer((): JSX.Element => {
  const { notifyStore } = useContext(StoreContext);
  const isTouchDevice = useMediaQuery('(hover: none)');

  const anchorOrigin: SnackbarOrigin | undefined = isTouchDevice
    ? { horizontal: 'center', vertical: 'top' }
    : undefined;

  const handleClose = (_e: unknown, _r?: SnackbarCloseReason): void => {
    notifyStore.closeNotify();
  };

  return (
    <Snackbar
      open={notifyStore.notify?.open}
      anchorOrigin={anchorOrigin}
      autoHideDuration={10000}
      onClose={handleClose}
      // action={action}
    >
      <Alert
        onClose={handleClose}
        severity={notifyStore.notify?.severity}
        sx={{ width: '100%' }}>
        {notifyStore.notify?.message}
      </Alert>
    </Snackbar>
  );
});

export default Notify;
