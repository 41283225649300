import { isEmpty, isNil, isNull, values } from 'lodash';

interface FullName {
  name: string;
  surname: string;
  patronymic: string;
}

/**
 * Возвращает массив числовых значений из переданного перечисления (enum)
 *
 * @template T объект типа Record, содержащий значения чисел или строк в ключах
 * @param {T} enm перечисление (enum)
 * @returns {number[]} массив числовых значений из переданного перечисления (enum)
 */
export const enum2NumberArray = <T extends Record<string, number | string>>(
  enm: T
): number[] =>
  values(enm)
    .filter((v) => typeof v === 'number')
    .map((v) => +v);

/**
 * Возвращает отформатированный российский номер телефона
 *
 * @param {string} phone исходный номер телефона
 * @returns {string} отформатированный номер телефона
 */
export function getFormattedRuPhone(phone: string): string {
  const cleaned = phone.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (!isNil(match) && match.length >= 6) {
    return `+${match[1] ?? ''} (${match[2] ?? ''}) ${match[3] ?? ''}-${match[4] ?? ''}-${
      match[5] ?? ''
    }`;
  }
  return phone;
}

/**
 * Возвращает полное имя из объекта, содержащего имя, фамилию и отчество
 *
 * @template T объект типа FullName
 * @param {T} data объект, содержащий имя, фамилию и отчество
 * @returns {string} полное имя в формате "Фамилия Имя Отчество"
 */
export function getFullName<T extends FullName>(data: T): string {
  if (isNull(data)) {
    return '';
  }

  const { name, surname, patronymic } = data;

  let res = surname;

  if (!isEmpty(res) && !isEmpty(name)) res += ' ';
  res += name;

  if (!isEmpty(res) && !isEmpty(patronymic)) res += ' ';
  res += patronymic;

  return res;
}

/**
 * Возвращает сокращенное имя из объекта, содержащего имя, фамилию и отчество
 *
 * @template T объект типа FullName
 * @param {T} data объект, содержащий имя, фамилию и отчество
 * @returns {string} сокращенное имя в формате "Фамилия И. О."
 */
export function getShortName<T extends FullName>(data: T): string {
  if (isNull(data)) {
    return '';
  }

  const { name, surname, patronymic } = data;

  const getFirstLetter = (str: string): string => (isEmpty(str) ? '' : str[0]) ?? '';

  const namePart = isEmpty(name)
    ? ''
    : isEmpty(surname)
    ? name
    : ` ${getFirstLetter(name)}.`;
  const patronymicPart = isEmpty(patronymic) ? '' : ` ${getFirstLetter(patronymic)}.`;

  return (surname ?? '') + namePart + patronymicPart;
}

/**
 * Возвращает сообщение об ошибке
 *
 * @template T объект типа Error
 * @param {T} err объект ошибки
 * @returns {string} сообщение об ошибке
 */
export function getErrorMessage<T extends Error>(err: T): string {
  return err.message || err.name || JSON.stringify(err);
}

/**
 * Форматирует числовое значение в формат валюты RUB
 *
 * @type {Intl.NumberFormat}
 */
export const currencyFormatter = new Intl.NumberFormat(undefined, {
  currency: 'RUB',
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

/**
 * Возвращает объект форматирования даты
 *
 * @type {Intl.DateTimeFormat}
 */
export const dateFormatter = new Intl.DateTimeFormat();

/**
 * Возвращает объект форматирования даты и времени в коротком формате
 *
 * @type {Intl.DateTimeFormat}
 */
export const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'short',
  timeStyle: 'short',
});

/**
 * Возвращает объект форматирования даты и времени в длинном формате (26 января 2024 г.)
 *
 * @type {Intl.DateTimeFormat}
 */
export const longDateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'long',
});

/**
 * Проверяет, является ли переданное значение числом или строкой, представляющей число
 *
 * @param {string} x проверяемое значение
 * @returns {boolean} true, если переданное значение является числом или строкой, представляющей число, иначе - false
 */
export const isNumeric = (x: string | number): boolean =>
  (typeof x === 'number' || typeof x === 'string') && !isNaN(Number(x)) && x !== '';

/**
 * Преобразует значение `null` в `undefined`. Если предоставлена функция `map`,
 * она будет применена к исходному значению перед возвратом.
 *
 * @template T - Тип исходного значения.
 * @template R - Тип возвращаемого значения.
 * @param {T | null} source - Исходное значение.
 * @returns {(T | R) | undefined} - Возвращает исходное значение или значение, преобразованное с помощью функции `map`, или `undefined`, если исходное значение равно `null`.
 * @example
 * nullable2undefinable(null); // returns undefined
 * nullable2undefinable('Hello'); // returns 'Hello'
 */
export function nullable2undefinable<T>(source: T | null): T | undefined;
/**
 * Преобразует значение `null` в `undefined`. Если предоставлена функция `map`,
 * она будет применена к исходному значению перед возвратом.
 *
 * @template T - Тип исходного значения.
 * @template R - Тип возвращаемого значения.
 * @param {T | null} source - Исходное значение.
 * @param {(obj: T) => R} [map] - Функция преобразования, которая будет применена к исходному значению.
 * @returns {(T | R) | undefined} - Возвращает исходное значение или значение, преобразованное с помощью функции `map`, или `undefined`, если исходное значение равно `null`.
 * @example
 * nullable2undefinable(null); // returns undefined
 * nullable2undefinable('Hello'); // returns 'Hello'
 * nullable2undefinable('Hello', str => str.length); // returns 5
 */
export function nullable2undefinable<T, R = T>(
  source: T | null,
  map: (obj: T) => R
): R | undefined;
export function nullable2undefinable<T, R = T>(
  source: T | null,
  map?: (obj: T) => R
): (T | R) | undefined {
  const mapper = map ?? ((s) => s);

  return isNil(source) ? undefined : mapper(source);
}

/**
 * Функция для очистки российского номера телефона.
 *
 * @param {string} phone - Входной номер телефона.
 * @returns {string} - Очищенный и отформатированный номер телефона.
 */
export function cleanPhone(phone: string): string {
  if (isEmpty(phone)) return '';

  const cleanedNumber = phone.replace(/\D/g, '');

  if (cleanedNumber.length !== 11) {
    return cleanedNumber.slice(0, 11);
  }

  const formattedNumber = cleanedNumber.startsWith('8')
    ? '7' + cleanedNumber.slice(1)
    : cleanedNumber;

  return formattedNumber;
}

/**
 * Функция, которая возвращает другую функцию, выполняющую переданный асинхронный обработчик без ожидания его завершения.
 * Это полезно, когда вы хотите запустить Promise в фоновом режиме, не ожидая его завершения и не обрабатывая ошибки.
 *
 * @template ARGS - тип аргументов передаваемых в функцию.
 * @param {(...args: ARGS) => Promise<unknown>} fn - асинхронная функция, которую нужно выполнить без ожидания.
 * @returns {(...args: ARGS) => void} функция, которая вызывает переданный обработчик без ожидания его завершения.
 */
export function intentionallyFloatingPromiseReturn<ARGS extends unknown[]>(
  fn: (...args: ARGS) => Promise<unknown>
): (...args: ARGS) => void {
  return (...args) => {
    void fn(...args);
  };
}
