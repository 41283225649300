import { createMongoAbility } from '@casl/ability';
import defineAbilityFor from 'core/permissions/defineAbilityFor';
import {
  getUserData as getUserDataFromLS,
  setUserData as setUserDataToLS,
} from 'core/utils/localStorage';
import { isNil } from 'lodash';
import { action, autorun, makeAutoObservable, toJS } from 'mobx';
import type RootStore from './rootStore';
import type { AppAbility } from 'core/permissions/defineAbilityFor';
import type AccountData from 'models/accountData';
// import jwtService from 'services/jwtService';

export default class UserStore {
  // private rootStore: RootStore;

  /**
   *
   */
  // constructor(_rootStore: RootStore) {
  //   this.rootStore = _rootStore;
  // }

  userData: AccountData | null = null;
  ability: AppAbility = this.getDefaultAbility();

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);

    this.setUserData(getUserDataFromLS());

    autorun(() => {
      const value = toJS(this.userData);

      setUserDataToLS(value);

      this.updateAbility();
    });
  }

  // signIn(user: { phone: string; password: string }, callback: VoidFunction): void {
  //   fakeAuthProvider.signin(() => {
  //     this.userData = user;
  //     callback();
  //   });
  // }

  updateAbility(): void {
    if (isNil(this.userData)) {
      this.ability = this.getDefaultAbility();
    } else {
      this.setAbilityForUser(this.userData);
    }
  }

  setAbilityForUser(user: AccountData): void {
    const ability = defineAbilityFor(user);

    this.ability.update(ability.rules);
  }

  setUserData = action((user: AccountData | null): void => {
    /*
          You can redirect the logged-in user to a specific route depending on his role
           */

    // history.location.state = {
    //   redirectUrl: user.redirectUrl, // for example 'apps/academy'
    // };

    // /*
    //   Set User Settings
    //    */
    // dispatch(setDefaultSettings(user.data.settings));

    this.userData = user;
  });

  private getDefaultAbility(): AppAbility {
    return createMongoAbility();
  }
}

//   logoutUser(): void {
//     // const { user } = getState().auth;

//     // if (!user.role || user.role.length === 0) {
//     //   // is guest
//     //   return null;
//     // }

//     // history.push({
//     //   pathname: '/',
//     // });

//     jwtService.logout();

//     this._userData = null;
//   }
// }

// const userStore = new UserStore();
// export default userStore;

// export const setUserData = (user: any) => async (dispatch, getState) => {
//   /*
//         You can redirect the logged-in user to a specific route depending on his role
//          */

//   history.location.state = {
//     redirectUrl: user.redirectUrl, // for example 'apps/academy'
//   };

//   /*
//     Set User Settings
//      */
//   dispatch(setDefaultSettings(user.data.settings));

//   dispatch(setUser(user));
// };

// export const logoutUser = () => async (dispatch, getState) => {
//   const { user } = getState().auth;

//   if (!user.role || user.role.length === 0) {
//     // is guest
//     return null;
//   }

//   history.push({
//     pathname: '/',
//   });

//   switch (user.from) {
//     case 'firebase': {
//       firebaseService.signOut();
//       break;
//     }
//     case 'auth0': {
//       auth0Service.logout();
//       break;
//     }
//     default: {
//       jwtService.logout();
//     }
//   }

//   dispatch(setInitialSettings());

//   return dispatch(userLoggedOut());
// };
