import { publicHttp } from 'core/utils/httpClient';
import type { SignInRequest, SignInResponse } from './dtoModels/auth/signInQuery';
import type {
  UpdateTokenRequest,
  UpdateTokenResponse,
} from 'services/dtoModels/auth/updateTokenQuery';

export class AuthService {
  async signIn(request: SignInRequest): Promise<SignInResponse> {
    const response = await publicHttp.post<SignInResponse>('auth', request);

    return response.data;
  }

  async signOut(): Promise<void> {
    await new Promise<void>((resolve) => {
      resolve();
    });
  }

  async updateToken(request: UpdateTokenRequest): Promise<UpdateTokenResponse> {
    const response = await publicHttp.post<UpdateTokenResponse>('auth/refresh', request);

    return response.data;
  }
}

const authService = new AuthService();
export default authService;
