import { ClientTypeEnum } from 'models/enums/clientTypeEnum';
import type ClientFormData from './clientFormData';

const clientTypeFields: { [K in keyof ClientFormData]: ClientTypeEnum[] } = {
  name: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],
  surname: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],
  patronymic: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],
  fullName: [ClientTypeEnum.Entity],
  phone: [
    ClientTypeEnum.Individual,
    ClientTypeEnum.IndividualEntrepreneur,
    ClientTypeEnum.Entity,
  ],
  email: [
    ClientTypeEnum.Individual,
    ClientTypeEnum.IndividualEntrepreneur,
    ClientTypeEnum.Entity,
  ],
  registrationAddress: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],
  type: [
    ClientTypeEnum.Individual,
    ClientTypeEnum.IndividualEntrepreneur,
    ClientTypeEnum.Entity,
  ],

  docDateOfIssue: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],
  docIssuedBy: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],
  docNumber: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],
  docSeries: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],
  docType: [ClientTypeEnum.Individual, ClientTypeEnum.IndividualEntrepreneur],

  entityAddress: [ClientTypeEnum.Entity],
  inn: [ClientTypeEnum.IndividualEntrepreneur, ClientTypeEnum.Entity],
  kpp: [ClientTypeEnum.Entity],
  ogrn: [ClientTypeEnum.Entity],
  paymentAccount: [ClientTypeEnum.IndividualEntrepreneur, ClientTypeEnum.Entity],
  managementFullName: [ClientTypeEnum.Entity],

  bankBIC: [ClientTypeEnum.IndividualEntrepreneur, ClientTypeEnum.Entity],
  bankName: [ClientTypeEnum.IndividualEntrepreneur, ClientTypeEnum.Entity],
  bankINN: [ClientTypeEnum.IndividualEntrepreneur, ClientTypeEnum.Entity],
  bankKPP: [ClientTypeEnum.IndividualEntrepreneur, ClientTypeEnum.Entity],
  bankCorrespondentAccount: [
    ClientTypeEnum.IndividualEntrepreneur,
    ClientTypeEnum.Entity,
  ],
};

export const getFieldSet = (type: ClientTypeEnum): Set<keyof ClientFormData> =>
  new Set<keyof ClientFormData>(
    (Object.keys(clientTypeFields) as Array<keyof ClientFormData>).filter((key) =>
      clientTypeFields[key].includes(type)
    )
  );

export const getAntiFieldSet = (type: ClientTypeEnum): Set<keyof ClientFormData> =>
  new Set<keyof ClientFormData>(
    (Object.keys(clientTypeFields) as Array<keyof ClientFormData>).filter(
      (key) => !clientTypeFields[key].includes(type)
    )
  );

export default clientTypeFields;
