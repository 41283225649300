import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Navigate } from 'react-router';
import StoreContext from 'stores/context/StoreContext';

const RouteNotAuthGuard = observer(
  ({ children }: { children: JSX.Element }): JSX.Element => {
    const { authStore } = useContext(StoreContext);

    if (authStore.isAuthenticated.get()) {
      return <Navigate to='/' state={{ replace: true }} />;
    }

    return children;
  }
);

export default RouteNotAuthGuard;
