import { InputAdornment, TextField } from '@mui/material';
import PhoneInput from './PhoneInput';
import type { TextFieldProps } from '@mui/material';
import type { FC } from 'react';

const PhoneField: FC<TextFieldProps> = (props) => (
  <TextField
    {...props}
    InputProps={{
      ...props.InputProps,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: PhoneInput as any,
      startAdornment: <InputAdornment position='start'>+</InputAdornment>,
    }}
  />
);

export default PhoneField as typeof TextField;
