import MyLocationIcon from '@mui/icons-material/MyLocation';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { forwardRef, useContext } from 'react';
import StoreContext from 'stores/context/StoreContext';
import type { IconButtonProps } from '@mui/material';
import type { FC } from 'react';

export interface MyLocationButtonProps extends Omit<IconButtonProps, 'onClick'> {
  onGeolocationDetermined: PositionCallback;
}

const MyLocationButton: FC<MyLocationButtonProps> = observer(
  forwardRef<HTMLButtonElement, MyLocationButtonProps>(function MyLocationButton(
    props,
    ref
  ) {
    const { onGeolocationDetermined, ...rest } = props;

    const { notifyStore } = useContext(StoreContext);

    const handleOnClick = (): void => {
      navigator.geolocation.getCurrentPosition(
        onGeolocationDetermined,
        () => {
          notifyStore.showNotify(
            'Не удалось определить местоположение. Проверьте разрешение',
            'error'
          );
        },
        { enableHighAccuracy: true }
      );
    };

    return (
      <IconButton {...rest} ref={ref} onClick={handleOnClick}>
        <MyLocationIcon />
      </IconButton>
    );
  })
);

export default MyLocationButton;
