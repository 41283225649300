import { yupResolver } from '@hookform/resolvers/yup';
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ClientForm from 'components/ClientForm/ClientForm';
import MyLocationButton from 'core/components/MyLocationButton';
import {
  getDefaultOrder,
  getGeolocationLink,
  getGeolocationString,
  parseGeolocationString,
} from 'core/utils/orderUtils';
import dayjs from 'dayjs';
import CardedPage from 'layout/pages/CardedPage';
import { isEmpty, isNil } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ClientDocumentTypeEnum } from 'models/enums/clientDocumentTypeEnum';
import { OrderStateEnum } from 'models/enums/orderStateEnum';
import { useContext, useMemo } from 'react';
import {
  FormContainer,
  TextareaAutosizeElement,
  TextFieldElement,
  useForm,
  useWatch,
} from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import * as clientService from 'services/clientService';
import * as orderService from 'services/orderService';
import StoreContext from 'stores/context/StoreContext';
import RequestCreatePageHeader from './RequestCreatePageHeader';
import orderFormSchema from './orderFormSchema';
import type OrderFormData from './orderFormData';
import type { Order } from 'models/order/order';
import type { FC } from 'react';
import type { CreateOrUpdateOrderRequest } from 'services/dtoModels/order/createOrUpdateOrderRequest';

const orderToFormData = (order: Order): OrderFormData => ({
  ...order,
  geolocation:
    isNil(order) || isNil(order?.geolocation)
      ? ''
      : getGeolocationString(order.geolocation),
  client:
    isNil(order.client) || isNil(order.client)
      ? null
      : {
          ...order.client,
          docDateOfIssue: isNil(order.client.docDateOfIssue)
            ? null
            : dayjs(order.client.docDateOfIssue),
        },
});

const RequestCreatePage: FC = observer(() => {
  const { notifyStore } = useContext(StoreContext);
  const navigate = useNavigate();

  const formContext = useForm<OrderFormData>({
    mode: 'onTouched',
    defaultValues: orderToFormData(getDefaultOrder()),
    resolver: yupResolver(orderFormSchema),
  });

  const formGeolocation = useWatch({ control: formContext.control, name: 'geolocation' });
  const geolocationLink = useMemo(
    () =>
      !isEmpty(formGeolocation) && isNil(formContext.formState.errors.geolocation)
        ? getGeolocationLink(formGeolocation)
        : '',
    [formGeolocation, formContext.formState.errors.geolocation]
  );

  const pageTitle = 'Новая заявка';

  const handleOnOrderSave = async (): Promise<void> => {
    try {
      if (!(await formContext.trigger())) {
        notifyStore.showNotify(
          'Не удалось создать заявку. Проверьте корректность введенных данных.',
          'error'
        );
        return;
      }

      const formData = formContext.getValues();
      const client = formData.client;

      if (isNil(client)) {
        throw new Error('Клиент не задан');
      }

      const { id: clientId } = await clientService.createClientAsync({
        ...client,
        phone: client?.phone?.replace(/\D/, '') ?? '',
        docType: ClientDocumentTypeEnum.Passport,
        docDateOfIssue: client.docDateOfIssue?.toDate(),
      });

      const tmpOrder: CreateOrUpdateOrderRequest = {
        address: formData.address,
        remark: formData.remark,
        warningComment: formData.warningComment,
        clientId,
        state: OrderStateEnum.Request,
        geolocation: !isEmpty(formData.geolocation)
          ? parseGeolocationString(formData.geolocation)
          : undefined,
      };

      const order = await orderService.createOrderAsync(tmpOrder);
      const currentOrderId = order.id;

      navigateToView(currentOrderId);
    } catch (err) {
      console.log(err);
    }
  };

  const navigateToView = (orderId: number): void => {
    navigate(`/requests/${orderId}/common`);
  };

  const handleGeolocationDetermined: PositionCallback = (position) => {
    const geolocation = getGeolocationString({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });

    formContext.setValue('geolocation', geolocation);
  };

  const isValidFormGeolocation =
    !isEmpty(formGeolocation) && isNil(formContext.formState.errors.geolocation);

  return (
    // {'TODO: Сделать loader на кнопку Сохранить'}

    <CardedPage
      title={pageTitle}
      header={
        <RequestCreatePageHeader
          onSave={() => {
            void handleOnOrderSave();
          }}
        />
      }>
      <FormContainer formContext={formContext}>
        <TextareaAutosizeElement
          fullWidth
          label='Примечание'
          name='remark'
          margin='normal'
        />

        <TextareaAutosizeElement
          fullWidth
          label='Предупреждение'
          name='warningComment'
          margin='normal'
        />

        <Typography component={'p'} variant='h6' mt={2}>
          Местоположение
        </Typography>
        <Stack direction={['column', 'row']} gap={[0, 2]}>
          <TextFieldElement
            fullWidth
            label='Адрес'
            name='address'
            type='text'
            margin='normal'
          />
          <TextFieldElement
            fullWidth
            label='Координаты'
            name='geolocation'
            type='text'
            margin='normal'
            helperText='Например (Широта, Долгота): 41.876090034, 21.429420990'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip
                    title={
                      isValidFormGeolocation
                        ? 'Посмотреть на карте'
                        : 'Использовать текущее местоположение'
                    }>
                    {/* Box Нужен для сохранения постоянного якоря для tooltip */}
                    <Box>
                      {isValidFormGeolocation ? (
                        <IconButton
                          aria-label='Посмотреть на карте'
                          target='_blank'
                          href={geolocationLink}
                          edge='end'>
                          <MapIcon />
                        </IconButton>
                      ) : (
                        <MyLocationButton
                          edge='end'
                          aria-label='Использовать текущее местоположение'
                          onGeolocationDetermined={handleGeolocationDetermined}
                        />
                      )}
                    </Box>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Typography variant='h5' mt={2}>
          Клиент
        </Typography>

        <Divider sx={{ mb: 2, mt: 0.5 }} />

        <ClientForm formPath='client' />
      </FormContainer>
    </CardedPage>
  );
});

export default RequestCreatePage;
