import { Paper } from '@mui/material';
import { memo } from 'react';
import SimplePage from './SimplePage/SimplePage';
import type { PaperProps } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

const PagePaper: FC<PaperProps & { disablePadding?: boolean }> = ({
  children,
  disablePadding,
  ...other
}) => (
  <Paper
    elevation={2}
    square={false}
    variant='elevation'
    sx={{
      display: 'flex',
      flexDirection: 'column',
      padding: disablePadding ?? false ? 0 : 2,
    }}
    {...other}>
    {children}
  </Paper>
);

const CardedPage: FC<PropsWithChildren<PageCardedProps>> = ({
  children,
  header,
  title,
  disablePadding,
}): JSX.Element => (
  <SimplePage title={title} header={header}>
    <PagePaper disablePadding={disablePadding}>{children}</PagePaper>
  </SimplePage>
);

interface PageCardedProps {
  header?: JSX.Element;
  title: string;
  disablePadding?: boolean;
}

export default memo(CardedPage);
