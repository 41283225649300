import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  Stack,
  Box,
  Typography,
  InputAdornment,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@mui/material';
import PhoneFieldElement from 'core/components/PhoneField/PhoneFieldElement';
import { getClientTypeName } from 'core/utils/clientUtils';
import { enum2NumberArray } from 'core/utils/commonUtils';
import { isEmpty, isNil } from 'lodash';
import { ClientTypeEnum } from 'models/enums/clientTypeEnum';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectElement, TextFieldElement, DatePickerElement } from 'react-hook-form-mui';
import * as dadataService from 'services/dadataService';
import { getAntiFieldSet, getFieldSet } from './clientTypeFields';
import type ClientFormData from './clientFormData';
import type { FC } from 'react';

const ClientForm: FC<ClientFormProps> = ({ formPath }) => {
  const formContext = useFormContext();

  const [bankDataLoading, setBankDataLoading] = useState(false);
  const [partyDataLoading, setPartyDataLoading] = useState(false);

  const path = !isNil(formPath) && !isEmpty(formPath) ? `${formPath}.` : '';

  const formClientType = useWatch({ control: formContext.control, name: `${path}type` });
  const formBankBIC = useWatch({ control: formContext.control, name: `${path}bankBIC` });
  const formINN = useWatch({ control: formContext.control, name: `${path}inn` });

  const isEntity = formClientType === ClientTypeEnum.Entity;
  const isIndividualEntrepreneur =
    formClientType === ClientTypeEnum.IndividualEntrepreneur;

  const bankBicChangedHandler = (): void => {
    void (async () => {
      await formContext.trigger([
        `${path}bankCorrespondentAccount`,
        `${path}paymentAccount`,
      ]);
    })();
  };

  const typeChangedHandler = (value: ClientTypeEnum): void => {
    void (async () => {
      await formContext.trigger(`${path}inn`);

      !isNil(value) &&
        getAntiFieldSet(value).forEach((s) => {
          formContext.resetField(`${path}${s}`);
        });
    })();
  };

  function fillBankFormByBIC(bic: string): void {
    setBankDataLoading(true);
    dadataService
      .getBankAsync(bic)
      .then((data) => {
        (
          [
            ['bankCorrespondentAccount', data.correspondentAccount],
            [`bankINN`, data.inn],
            [`bankKPP`, data.kpp],
            [`bankName`, data.name],
          ] satisfies Array<[keyof ClientFormData, string]>
        ).forEach(([k, v]) => {
          formContext.setValue(`${path}${k}`, v);
        });

        setBankDataLoading(false);
        return data;
      })
      .catch((err) => {
        setBankDataLoading(false);
        throw err;
      });
  }

  function fillPartyFormByINN(bic: string): void {
    setPartyDataLoading(true);
    dadataService
      .getPartyAsync(bic)
      .then((data) => {
        const clientFields = getFieldSet(formClientType);

        (
          [
            [`entityAddress`, data.address],
            [`inn`, data.inn],
            [`kpp`, data.kpp],
            [`fullName`, data.fullName],
            [`managementFullName`, data.managementFullName],
            [`ogrn`, data.ogrn],
          ] satisfies Array<[keyof ClientFormData, string]>
        )
          .filter(([field]) => clientFields.has(field))
          .forEach(([k, v]) => {
            formContext.setValue(`${path}${k}`, v);
          });

        setPartyDataLoading(false);
        return data;
      })
      .catch((err) => {
        setPartyDataLoading(false);
        throw err;
      });
  }

  return (
    <Stack direction={'column'} gap={2}>
      <Box>
        <Typography variant='h6'>Общее</Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
            columnGap: 2,
          }}>
          <PhoneFieldElement label='Телефон' name={`${path}phone`} margin='normal' />
          <TextFieldElement
            type='email'
            label='Email'
            name={`${path}email`}
            margin='normal'
          />
          <SelectElement
            required
            margin='normal'
            label='Тип'
            name={`${path}type`}
            onChange={typeChangedHandler}
            options={enum2NumberArray(ClientTypeEnum).map((v) => ({
              id: v,
              label: getClientTypeName(v),
            }))}
          />
          {!isEntity && (
            <>
              <TextFieldElement label='Фамилия' margin='normal' name={`${path}surname`} />
              <TextFieldElement
                required
                label='Имя'
                margin='normal'
                name={`${path}name`}
              />
              <TextFieldElement
                label='Отчество'
                margin='normal'
                name={`${path}patronymic`}
              />
            </>
          )}
        </Box>
      </Box>

      {!isEntity && (
        <Box>
          <Typography variant='h6'>Паспорт РФ</Typography>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
              columnGap: 2,
            }}>
            <TextFieldElement label='Серия' name={`${path}docSeries`} margin='normal' />
            <TextFieldElement label='Номер' name={`${path}docNumber`} margin='normal' />
            <TextFieldElement label='Выдан' name={`${path}docIssuedBy`} margin='normal' />

            <DatePickerElement
              disableFuture
              label='Дата выдачи'
              name={`${path}docDateOfIssue`}
              slotProps={{
                field: { clearable: true },
              }}
              inputProps={{ margin: 'normal' }}
            />

            <TextFieldElement
              label='Адрес регистрации'
              name={`${path}registrationAddress`}
              margin='normal'
            />
          </Box>
        </Box>
      )}

      {(isEntity || isIndividualEntrepreneur) && (
        <Box>
          <Typography variant='h6'>Реквизиты</Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
              columnGap: 2,
            }}>
            <TextFieldElement
              label='ИНН'
              name={`${path}inn`}
              margin='normal'
              InputProps={{
                endAdornment:
                  !isEmpty(formINN) && isNil(formContext.formState.errors.inn) ? (
                    <InputAdornment position='end'>
                      {partyDataLoading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : (
                        <Tooltip title='Заполнить реквизиты по ИНН'>
                          <IconButton
                            aria-label='Заполнить реквизиты по ИНН'
                            onClick={() => {
                              fillPartyFormByINN(formINN);
                            }}>
                            <AutoFixHighIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ) : undefined,
              }}
            />

            {isEntity && (
              <>
                <TextFieldElement
                  required
                  label='Наименование'
                  margin='normal'
                  name={`${path}fullName`}
                />
                <TextFieldElement label='КПП' name={`${path}kpp`} margin='normal' />
                <TextFieldElement label='ОГРН' name={`${path}ogrn`} margin='normal' />
                <TextFieldElement
                  label='Полное имя руководителя'
                  name={`${path}managementFullName`}
                  margin='normal'
                />
                <TextFieldElement
                  label='Юридический адрес'
                  name={`${path}entityAddress`}
                  margin='normal'
                />
              </>
            )}

            <TextFieldElement
              label='Расчетный счет'
              name={`${path}paymentAccount`}
              margin='normal'
            />
          </Box>

          <Typography variant='subtitle2' mt={2}>
            Реквизиты банка
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
              columnGap: 2,
            }}>
            <TextFieldElement
              label='БИК банка'
              name={`${path}bankBIC`}
              margin='normal'
              onChange={bankBicChangedHandler}
              InputProps={{
                endAdornment:
                  !isEmpty(formBankBIC) && isNil(formContext.formState.errors.bankBIC) ? (
                    <InputAdornment position='end'>
                      {bankDataLoading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : (
                        <Tooltip title='Заполнить реквизиты банка по БИК'>
                          <IconButton
                            aria-label='Заполнить реквизиты банка по БИК'
                            onClick={() => {
                              fillBankFormByBIC(formBankBIC);
                            }}>
                            <AutoFixHighIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ) : undefined,
              }}
            />
            <TextFieldElement
              label='Название банка'
              name={`${path}bankName`}
              margin='normal'
            />
            <TextFieldElement label='ИНН банка' name={`${path}bankINN`} margin='normal' />
            <TextFieldElement label='КПП банка' name={`${path}bankKPP`} margin='normal' />
            <TextFieldElement
              label='Корреспондентский счет банка'
              name={`${path}bankCorrespondentAccount`}
              margin='normal'
            />
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export interface ClientFormProps {
  formPath?: string;
}

export default ClientForm;
