import { http } from 'core/utils/httpClient';
import { isNil } from 'lodash';
import type { ClientResponse } from './dtoModels/client/clientResponse';
import type { CreateOrUpdateClientRequest } from './dtoModels/client/createOrUpdateClientRequest';
import type { GetClientsRequest } from './dtoModels/client/getClientsRequest';
import type { PaginatedList } from './dtoModels/paginatedList';
import type { Paginator } from './dtoModels/paginator';
import type { Client } from 'models/client';

export const mapClient = (source: ClientResponse): Client => ({
  ...source,
  docDateOfIssue: !isNil(source.docDateOfIssue)
    ? new Date(source.docDateOfIssue)
    : undefined,
});

export const mapClients = (source: ClientResponse[]): Client[] =>
  source.map((s) => mapClient(s));

export async function getClientsAsync(
  request: GetClientsRequest & Paginator
): Promise<PaginatedList<Client>> {
  const { data } = await http.get<PaginatedList<ClientResponse>>('clients', {
    params: request,
  });
  return { ...data, items: mapClients(data.items) };
}

export async function getClientAsync(clientId: number): Promise<Client> {
  const { data } = await http.get<ClientResponse>(`clients/${clientId}`);
  return mapClient(data);
}

export async function updateClientAsync(
  clientId: number,
  request: CreateOrUpdateClientRequest
): Promise<Client> {
  const { data } = await http.put<ClientResponse>(`clients/${clientId}`, request);
  return mapClient(data);
}

export async function createClientAsync(
  request: CreateOrUpdateClientRequest
): Promise<Client> {
  const { data } = await http.post<ClientResponse>('clients', request);
  return mapClient(data);
}
