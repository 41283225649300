import { Box, Button, Typography } from '@mui/material';
import { Link, useRouteError } from 'react-router-dom';

function RootErrorBoundary(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: { statusText: string; message: string } = useRouteError() as any;

  return (
    <Box
      display={'flex'}
      flexDirection='column'
      alignItems={'center'}
      justifyContent='center'
      p={2}
      height={'100%'}
      width={'100%'}>
      <Box textAlign={'center'} maxWidth={640}>
        <Typography variant='h1' color='inherit' mb={2}>
          Oops!
        </Typography>
        <Typography variant='caption' component='div' color='inherit' mb={8}>
          I did it again
        </Typography>

        <Typography variant='h5' color='textSecondary' mb={8}>
          Извините, произошла непредвиденная ошибка
        </Typography>

        <Typography variant='h6' color='textSecondary' mb={8}>
          {error.statusText || error.message}
        </Typography>

        <Button variant='contained' size='large' to='/' component={Link}>
          На главную
        </Button>
      </Box>
    </Box>
  );
}

export default RootErrorBoundary;
