import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import useDocumentTitle from 'core/hooks/useDocumentTitle';
import { isNil } from 'lodash';
import { memo, useEffect } from 'react';
import PageSimpleHeader from './SimplePageHeader';
import type { FC, PropsWithChildren } from 'react';

const PageContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  height: '100%',
  flex: '1 1 auto',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  position: 'relative',
  [theme.breakpoints.down('sm')]: { paddingLeft: 0, paddingRight: 0 },
}));

const SimplePage: FC<PropsWithChildren<SimplePageProps>> = ({
  title,
  header,
  children,
}) => {
  const [_, setTitle] = useDocumentTitle(title);

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  return (
    <PageContainer maxWidth='xl'>
      {!isNil(header) && <PageSimpleHeader>{header}</PageSimpleHeader>}
      {!isNil(children) && children}
    </PageContainer>
  );
};

export interface SimplePageProps {
  title: string;
  header?: JSX.Element;
}

export default memo(SimplePage);
