import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import StoreContext from 'stores/context/StoreContext';

/**
 *
 * @param root0
 * @param root0.children
 */
const RouteAuthGuard = observer(
  ({ children }: { children: JSX.Element }): JSX.Element => {
    // const auth = useAuth();
    // const auth = UserStore.userData;
    const location = useLocation();
    const { authStore } = useContext(StoreContext);

    if (!authStore.isAuthenticated.get()) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to='/login' state={{ from: location }} replace />;
    }

    return children;
  }
);

export default RouteAuthGuard;
